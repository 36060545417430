@import-normalize;
@font-face {
  font-family: 'Graphik';
  src: URL('./resources/fonts/Graphik-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

.MuiGridPanel-root{
  position: fixed !important; 
  will-change: transform; 
  top: 24vh !important;
  left: auto !important;
  right: 16vw !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

